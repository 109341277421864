import { AbstractControl, ValidatorFn } from '@angular/forms';

export function latitudeLongitudeValidator(): ValidatorFn {
  return (control: AbstractControl): Record<string, boolean> | null => {
    const value = control.value;

    if (value && typeof value === 'string') {
      // Split the string into latitude and longitude parts
      const parts = value.split(',');

      // Check if there are exactly two parts
      if (parts.length !== 2) {
        return { invalidLatLng: true };
      }

      // Trim and parse latitude and longitude
      const lat = parseFloat(parts[0].trim());
      const lng = parseFloat(parts[1].trim());

      // Validate latitude and longitude ranges
      if (isNaN(lat) || lat < -90 || lat > 90 || isNaN(lng) || lng < -180 || lng > 180) {
        return { invalidLatLng: true };
      }
    } else {
      return { invalidLatLng: true };
    }

    return null; // Validation passes
  };
}
