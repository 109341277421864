import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SiteDetailsResponse } from '@shared/interfaces/site.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SiteService {
  constructor(private readonly http: HttpClient) {}

  getSiteCodes(search: string): Observable<SiteDetailsResponse[] | null> {
    return this.http.get(
      `assetmanagement/sites?code__icontains=${search}&name__icontains=${search}`,
      {
        headers: { 'hide-loader': '1' },
      },
    ) as Observable<SiteDetailsResponse[] | null>;
  }
}
