<div *ngIf="legalFormGroup && legalFormList" class="legal-form-container">
  <div class="custom-form" [formGroup]="legalFormGroup">
    <div class="d-flex justify-content-start align-items-start mt-15 g-15 mb-10">
      <div class="form-input">
        <app-input-field
          [id]="'spv-company-name'"
          label="SPV Company Name"
          formControlName="companyNameSPV"
          placeholder="Name"
          [error]="
            legalFormGroup.get('legalRepresentativeSPV')?.touched &&
            legalFormGroup.errors &&
            legalFormGroup.errors['missingCompanyName']
          "
        />
        @if (
          legalFormGroup.get('legalRepresentativeSPV')?.touched &&
          legalFormGroup.errors &&
          legalFormGroup.errors['missingCompanyName']
        ) {
          <div class="error-message">
            SPV Company Name is required with Legal Representative SPV
          </div>
        }
      </div>

      <div class="form-input">
        <app-input-field
          [id]="'legal-representative-svp'"
          label="Legal Representative of the SPV"
          formControlName="legalRepresentativeSPV"
          placeholder="Name"
          [error]="
            legalFormGroup.get('legalRepresentativeSPV')?.touched &&
            legalFormGroup.get('legalRepresentativeSPV')?.invalid
          "
        />
      </div>
    </div>

    <div class="full-width">
      <div
        formArrayName="legalFormList"
        *ngFor="let form of legalFormList.controls; let i = index"
        class="mt-10"
      >
        <div class="d-flex justify-content-start align-items-center">
          <div [formGroupName]="i" class="d-flex g-15 justify-content-start flex-wrap">
            <div class="form-input">
              <app-input-field
                [id]="'board-member-spv'"
                label="Board Member of the SPV"
                formControlName="boardMemberSPV"
                placeholder="Name"
                [error]="form.get('boardMemberSPV')?.touched && form.get('boardMemberSPV')?.invalid"
              />
            </div>

            <div class="form-input">
              <app-input-field
                [id]="'board-member-role'"
                label="Board Member’s Role"
                formControlName="boardMemberRoleSPV"
                placeholder="Role"
                [error]="
                  form.get('boardMemberRoleSPV')?.touched && form.get('boardMemberRoleSPV')?.invalid
                "
              />
            </div>
          </div>
          @if (itemsLength > 1) {
            <div class="button-remove-legal-row">
              <p-button
                tabindex="0"
                icon="pi pi-trash"
                [rounded]="true"
                [text]="true"
                (click)="removeItem(i)"
                (keyup.enter)="removeItem(i)"
              />
            </div>
          }
        </div>
      </div>
    </div>
  </div>
  <div class="add-button">
    <p-button
      tabindex="0"
      label="Add Another"
      [outlined]="true"
      icon="pi pi-plus"
      (click)="addNewItem()"
      (keyup.enter)="addNewItem()"
    />
  </div>
</div>
