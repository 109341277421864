<div class="form-container">
  <form [formGroup]="projectForm" (ngSubmit)="onSubmit()">
    <p-accordion [multiple]="true" [activeIndex]="[0, 1, 2, 3, 4, 5]">
      <p-accordionTab header="Project">
        <div class="content">
          <app-general-form [regions]="regions" [data]="projectDetails" />
        </div>
      </p-accordionTab>
      <p-accordionTab header="Milestones">
        <div class="content">
          <app-time-frame-form [data]="projectDetails" />
        </div>
      </p-accordionTab>
      <p-accordionTab header="Legal">
        <div class="content">
          <app-legal-form [data]="projectDetails" [mode]="mode" />
        </div>
      </p-accordionTab>
      <p-accordionTab header="Attachments">
        <div class="content attachment-section" style="width: 40%">
          <app-attachment-form
            [data]="legalAttachmentFormData"
            label="Attach any link"
            [required]="false"
            placeholder="Paste URL"
            [showUploadFile]="true"
            formName="attachmentFormLegal"
            uploadDescription="Drop files to attach to this project or"
            [entityType]="DocumentEntityType.LEGAL"
          >
          </app-attachment-form>
        </div>
      </p-accordionTab>
      <p-accordionTab header="Project document source">
        <div class="content attachment-section" style="width: 40%">
          <app-attachment-form
            [data]="projectDetails"
            label="Sharepoint folder link"
            [required]="false"
            placeholder="Paste Sharepoint URL of this project’s document"
          >
          </app-attachment-form>
        </div>
      </p-accordionTab>
    </p-accordion>

    <div class="d-flex justify-content-end g-13 footer-buttons">
      <p-button
        type="button"
        label="Cancel"
        [outlined]="true"
        (click)="onCancel()"
        (keyup.enter)="onCancel()"
        tabindex="0"
      />
      <p-button
        type="submit"
        [label]="submitButtonLabel"
        [outlined]="false"
        [disabled]="isFormSubmitDisabled"
      />
    </div>
  </form>
</div>
