import { ProjectStatusName } from '@shared/enums/project/project-status';
import { DropdownOption } from '@shared/interfaces';

export const TECHNOLOGIES: DropdownOption[] = [
  { name: 'PV' },
  { name: 'BESS' },
  { name: 'PV+BESS' },
];

export const REGIONS: DropdownOption[] = [
  { name: 'North America' },
  { name: 'EMEA' },
  { name: 'LATAM' },
  { name: 'APAC' },
];

export const PROJECT_STATUS: DropdownOption[] = [
  { name: ProjectStatusName.INACTIVE },
  { name: ProjectStatusName.ACTIVE },
  { name: ProjectStatusName.UNDER_CONSTRUCTION },
];

export const PROJECT_DETAILS_TAB_MAPPING = {};
