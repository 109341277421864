<div *ngIf="projectForm && timeFrameFormControl" class="d-flex g-13">
  <div class="full-width custom-form" [formGroup]="timeFrameFormControl">
    <div class="d-flex g-15 justify-content-start flex-wrap">
      <!-- COD Date -->
      <div class="form-input">
        <app-input-template
          label="COD Date"
          [required]="true"
          [error]="
            !timeFrameFormControl.get('codDate')?.valid &&
            timeFrameFormControl.get('codDate')?.dirty
          "
        >
          <ng-template #inputTemplate let-error="error">
            <p-calendar
              [id]="'cod-date'"
              formControlName="codDate"
              [iconDisplay]="'input'"
              [showIcon]="true"
              inputId="icondisplay"
              placeholder="Select Date"
              [ngClass]="{ 'error-date': error, 'ng-invalid ng-dirty': error }"
              [dateFormat]="dateFormat"
            />
          </ng-template>
        </app-input-template>
      </div>

      <!-- NTP Date -->
      <div class="form-input">
        <app-input-template
          label="NTP Date"
          [required]="true"
          [error]="
            !timeFrameFormControl.get('ntpDate')?.valid &&
            timeFrameFormControl.get('ntpDate')?.dirty
          "
        >
          <ng-template #inputTemplate let-error="error">
            <p-calendar
              [id]="'ntp-date'"
              formControlName="ntpDate"
              [iconDisplay]="'input'"
              [showIcon]="true"
              inputId="icondisplay"
              placeholder="Select Date"
              [ngClass]="{ 'error-date': error, 'ng-invalid ng-dirty': error }"
              [dateFormat]="dateFormat"
            />
          </ng-template>
        </app-input-template>
      </div>

      <!-- SC Date -->
      <div class="form-input">
        <app-input-template
          label="SC Date"
          [required]="true"
          [error]="
            !timeFrameFormControl.get('scDate')?.valid && timeFrameFormControl.get('scDate')?.dirty
          "
        >
          <ng-template #inputTemplate let-error="error">
            <p-calendar
              [id]="'sc-date'"
              formControlName="scDate"
              [iconDisplay]="'input'"
              [showIcon]="true"
              inputId="icondisplay"
              placeholder="Select Date"
              [ngClass]="{ 'error-date': error, 'ng-invalid ng-dirty': error }"
              [dateFormat]="dateFormat"
            />
          </ng-template>
        </app-input-template>
      </div>

      <!-- PAC Date -->
      <div class="form-input">
        <app-input-template
          label="PAC Date"
          [error]="
            !timeFrameFormControl.get('pacDate')?.valid &&
            timeFrameFormControl.get('pacDate')?.dirty
          "
        >
          <ng-template #inputTemplate let-error="error">
            <p-calendar
              [id]="'pac-date'"
              formControlName="pacDate"
              [iconDisplay]="'input'"
              [showIcon]="true"
              inputId="icondisplay"
              placeholder="Select Date"
              [ngClass]="{ 'error-date': error, 'ng-invalid ng-dirty': error }"
              [dateFormat]="dateFormat"
            />
          </ng-template>
        </app-input-template>
      </div>

      <!-- IAC Date -->
      <div class="form-input">
        <app-input-template
          label="IAC Date"
          [error]="
            !timeFrameFormControl.get('iacDate')?.valid &&
            timeFrameFormControl.get('iacDate')?.dirty
          "
        >
          <ng-template #inputTemplate let-error="error">
            <p-calendar
              [id]="'iac-date'"
              formControlName="iacDate"
              [iconDisplay]="'input'"
              [showIcon]="true"
              inputId="icondisplay"
              placeholder="Select Date"
              [ngClass]="{ 'error-date': error, 'ng-invalid ng-dirty': error }"
              [dateFormat]="dateFormat"
            />
          </ng-template>
        </app-input-template>
      </div>

      <!-- FAC Date -->
      <div class="form-input">
        <app-input-template
          label="FAC Date"
          [error]="
            !timeFrameFormControl.get('facDate')?.valid &&
            timeFrameFormControl.get('facDate')?.dirty
          "
        >
          <ng-template #inputTemplate let-error="error">
            <p-calendar
              [id]="'fac-date'"
              formControlName="facDate"
              [iconDisplay]="'input'"
              [showIcon]="true"
              inputId="icondisplay"
              placeholder="Select Date"
              [ngClass]="{ 'error-date': error, 'ng-invalid ng-dirty': error }"
              [dateFormat]="dateFormat"
            />
          </ng-template>
        </app-input-template>
      </div>
    </div>
  </div>
</div>
